/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import '~ngx-toastr/toastr.css';
:root {
  --primaryColor: rgba(12, 99, 150, 0.82);
  --secondaryColor: #76ba991a;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

/* mat-dialog css starts here */

.mat-dialog-container {
  padding: 0 0 24px 0 !important;
}

::ng-deep .mat-tab-header {
  border-bottom: 1px solid transparent !important;
}

.mat-tab-label.mat-tab-label-active:not(.mat-tab-disabled),
.mat-tab-label.mat-tab-label-active.cdk-keyboard-focused:not(.mat-tab-disabled) {
  background: var(--secondaryColor, #76ba9980);
  opacity: 1;
  color: var(--primaryColor, #249953);
  font-weight: bold;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: 2px solid;
  border-color: var(--primaryColor, #249953);
}

.custom-tooltip {
  position: relative;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 4px 4px 0 0;
}
.content-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .content-center-label{
    font-size: 10px;
  }
}
/* mat-dialog css ends here */

.main-content-active .content-wrapper {
  padding-top: 70px !important;
}

#side-bar.active .main-content {
  margin-left: 100px !important;
}

#side-bar.active #header {
  padding-left: 125px;
  z-index: 9 !important;
}

.page-title {
  margin: 10px 0 20px 13px;
  font-size: 1em;
  color: #5A5A5A;
}

.main-content {
  margin-left: 300px;
  margin-bottom: 0 !important;
  -webkit-transition: margin-left 0.2s ease;
  transition: margin-left 0.2s ease;
  overflow: hidden;
  margin-top: 74px;
  background: var(--secondaryColor, #76ba9980) !important;
  background-color: var(--secondaryColor, #76ba9980);
}

#side-bar.active .main-content {
  margin-left: 100px;
}

.main-content-active {
  margin-left: 100px;
  margin-bottom: 0 !important;
  -webkit-transition: margin-left 0.2s ease;
  transition: margin-left 0.2s ease;
  overflow: hidden;
  /*margin-top: 55px;*/
  background: #66666614;
}


@media (max-width: 768px) {
  .main-content-active {
    margin-left: 170px;
    margin-bottom: 0 !important;
    -webkit-transition: margin-left 0.2s ease;
    transition: margin-left 0.2s ease;
    overflow: hidden;
    margin-top: 55px;
    background: #66666614;
  }

  #side-bar .main-content {
    margin-left: 170px !important;
  }

  #side-bar #header {
    padding-left: 100px;
    z-index: 9 !important;
  }

}

/*shimmer-effect-css*/

.shimmer-loader {
  padding: 15px 15px 15px 15px;
  background: #fff;
  border-radius: 10px;
}

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmereffect;
  animation-timing-function: linear;

  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: shimmereffect;
  -webkit-animation-timing-function: linear;

}

.picture {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.button {
  height: 40px;
  width: 120px;
  margin-top: 10px;
  border-radius: 25px;
}

.lines {
  height: 35px;
  margin-top: 10px;
  width: 300px;
  margin-bottom: 20px;
  display: block !important;
}

.bottom-lines {
  height: 25px;
  width: 200px;
}

.bottom-button {
  height: 50px;
  width: 250px;
  border-radius: 5px;
}

@keyframes shimmereffect {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes shimmereffect {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

/*scrollbar-css*/
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #9a9999;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #151416;
  color: #fff;
}

.mat-horizontal-stepper-header {
  pointer-events: none;
}

.z-index_high {
  z-index: 999;
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--primaryColor) !important;
}


/*theme_input*/
.input-block {
  position: relative;
}

.input-block input {
  font-weight: 500;
  color: #363636;
  width: 100%;
  padding: 12px 0 12px 15px;
  border-radius: 30px;
  border: 2px solid #808080;
  outline: none;
}

.placeholder {
  opacity: 1;
}

.input-block span.placeholder {
  position: absolute;
  margin: 14px 0;
  padding: 0 4px;
  font-family: Roboto, sans-serif;
  color: #808080;
  top: 0;
  left: 17px;
  transition: all 0.2s;
  transform-origin: 0% 0%;
  background: none;
  pointer-events: none;
}

.input-block input:valid + span.placeholder,
.input-block input:focus + span.placeholder {
  transform: scale(0.8) translateY(-27px);
  background: #fff;
}

.input-block input:focus {
  color: #363636;
  border-color: #808080;
}

.input-block input:focus + span.placeholder {
  color: #808080;
}

.skills_drop ::ng-deep.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 9px !important;
}

@media (max-width: 1200px) {
  .user-sidebar {
    padding: 10px;
  }
}

.theme-text-color {
  color: var(--primaryColor);
}

.theme-bg-color {
  background: var(--primaryColor) !important;
  background-color: var(--primaryColor);
}

.theme-border-color {
  border-color: var(--primaryColor);
}

.theme-btn-color {
  background: var(--primaryColor) !important;
}

.theme-btn-sec-color {
  background: var(--secondaryColor) !important;
}

.themeSecondary-color {
  color: var(--secondaryColor);
}

.themeSecondary-bg-color {
  background: var(--secondaryColor) !important;
  background-color: var(--secondaryColor);
}

.themeSecondary-border-color {
  border-color: var(--secondaryColor);
}

.theme-svg-color {
  fill: var(--primaryColor) !important;
}

.angular-editor-toolbar {
  input[type=color]:not(.color-picker) {
    display: initial !important;
    position: absolute;
    visibility: hidden;
    border: none;
    margin: 0;
    padding: 0;
    height: 0;
    width: 0;
  }
}

.table_wrap::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.table_wrap::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.table_wrap::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
